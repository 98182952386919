import '../styles/index.scss';
var Flickity = require('flickity');

import { gsap } from "gsap";
import { SplitText } from "gsap/SplitText.js";


import videojs from "video.js";
import "videojs-contrib-dash";


window.HELP_IMPROVE_VIDEOJS = false;

function iOSFirefox() {

  return [
	'iPad Simulator',
	'iPhone Simulator',
	'iPod Simulator',
	'iPad',
	'iPhone',
	'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document) || navigator.userAgent.indexOf("Firefox") != -1;
}





$(()=>{

	// wave-iii
	
	window.scale = 1;
	
	gsap.registerPlugin(SplitText);
	
	function isTouchDevice() {
	  return (('ontouchstart' in window) ||
		 (navigator.maxTouchPoints > 0) ||
		 (navigator.msMaxTouchPoints > 0));
	}
	
	setTimeout(()=>{
		
		$("body").attr("style", "");
	}, 1000);	
	
	//window.updatePlayChaser = null;
	

	
	// Load the IFrame Player API code asynchronously.
	var tag = document.createElement('script');
	tag.src = "https://www.youtube.com/player_api";
	var firstScriptTag = document.getElementsByTagName('script')[0];
	firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

	// Replace the 'ytplayer' element with an <iframe> and
	// YouTube player after the API code downloads.
	var player = null;
	window.youtubeReady = false;
	window.onYouTubePlayerAPIReady = ()=>{
		window.youtubeReady = true;
	};
	
	
	window.mode = "unknown";
	window.navCarousel = null;
	
	
	
	
	
	let openChaptersNav = ()=>{
		//show
		$("a#past-chapters-toggle").addClass("active");
		$("#chapters-menu").addClass("active");
		$(".page-dimmer").addClass("show");
		$("nav.top-nav .top-bar").addClass("active");
	
		$("body").addClass("noscroll");
		$("html, body").animate({ scrollTop: 0 }, 500);
		
		$("nav.top-nav #chapters-menu").attr("style","");
	
		if (window.navCarousel != null)
			window.navCarousel.resize();
			
		setTimeout(()=>{
			$(".page-dimmer").addClass("active");
		}, 10);
	};
	
	let closeChaptersNav = ()=>{
		// hide
		$("a#past-chapters-toggle").removeClass("active");
		$("#chapters-menu").removeClass("active");
		$(".page-dimmer").removeClass("active");
		$("nav.top-nav .top-bar").removeClass("active");
		
		$("nav.top-nav #chapters-menu").attr("style","");
	
		$("body").removeClass("noscroll");
		
		setTimeout(()=>{
			$(".page-dimmer").removeClass("show");
		}, 501);
	};
	
	
	
	
	let openMobileMenu = ()=>{
		$("nav.top-nav .menu.m-only").removeClass("show");
		$("nav.top-nav .close.m-only").addClass("show");
		
		$("nav.top-nav a.logo").addClass("hide");
		

		$(".page-dimmer").addClass("show");
		$("nav.top-nav .mobile-menu").addClass("active");
		
		$("body").addClass("noscroll");
		$("html, body").animate({ scrollTop: 0 }, 500);
		
		
		
		
		let reverseScale = 1/($(window).width()/750);
		
		let availableHeight = Math.floor($(window).height()*reverseScale) - 800;
		
		$("nav.top-nav #chapters-menu").attr("style","max-height: "+availableHeight+"px;");
		
		setTimeout(()=>{
			$(".page-dimmer").addClass("active");
		}, 10);
		
	};
	
	let closeMobileMenu = ()=> {
		
		$("nav.top-nav .menu.m-only").addClass("show");
		$("nav.top-nav .close.m-only").removeClass("show");
		
		$("nav.top-nav a.logo").removeClass("hide");
		
		$(".page-dimmer").removeClass("active");
		$("nav.top-nav .mobile-menu").removeClass("active");
		
		$("body").removeClass("noscroll");
		
		$("nav.top-nav #chapters-menu").attr("style","");
		
		closeChaptersNavMobile();
		
		setTimeout(()=>{
			$(".page-dimmer").removeClass("show");
		}, 501);
	};
	
	let openChaptersNavMobile = ()=>{
		$("a#past-chapters-toggle").addClass("active");
		$("#chapters-menu").addClass("active");
	};
	
	let closeChaptersNavMobile = ()=>{
		$("a#past-chapters-toggle").removeClass("active");
		$("#chapters-menu").removeClass("active");
	};
	
	
	
	
	let handleScroll = (e)=>{
		
		if (window.allowPara)
		{
			let buildTriggers = $(".scrollBuild");
			let windowHeight = $(window).height();
		
			for (let t=0; t<buildTriggers.length; t++)
			{
				let bounds = buildTriggers[t].getBoundingClientRect();
			
				if (! $(buildTriggers[t]).hasClass("scrollBuildActivate") && bounds.y < windowHeight * 0.75)
				{
					$(buildTriggers[t]).addClass("scrollBuildActivate");
					
					if($(buildTriggers[t]).attr("data-sibling"))
					{
						$($(buildTriggers[t]).attr("data-sibling")).addClass("scrollBuildActivate");
					}
					
				
				
					// line by line build
				
				
					let lineBuilds = $(buildTriggers[t]).find(".scrollBuildByLine");
					if (lineBuilds.length)
					{
						let tl = gsap.timeline();
						let linesArray = [];
				
						let splitTextArr = [];
						let mode = "from-right";
						
						let delay = 0;
				
						for (let l=0; l<lineBuilds.length; l++)
						{
							let mySplitText = new SplitText(lineBuilds[l], { type: "lines" });
							splitTextArr.push(mySplitText);
					
							let lines = mySplitText.lines; //an array of all the divs that wrap each character
					
							linesArray = linesArray.concat(lines);
							
							if (! $(lineBuilds[l]).hasClass("from-right"))
								mode = "from-left";
								
							if ($(lineBuilds[l]).attr("data-delay"))
								delay = parseInt($(lineBuilds[l]).attr("data-delay"));
						}
						
						
						if (linesArray.length)
						{
							let speed = $(lineBuilds[0]).attr("data-speed");
							
							if (speed == null)
								speed = 1;
								
							let stagger = $(lineBuilds[0]).attr("data-stagger");
							
							if (stagger == null)
								stagger = 0.25;
								
							console.log("speed",speed);
						
							tl.from(linesArray, {
							  x: mode == "from-right" ? 200 : -200,
							  opacity: 0,
							  duration: speed,
							  stagger: stagger,
							  delay: delay/1000
							});
						
							tl.eventCallback("onComplete", ()=>{
								for (let st=0; st < splitTextArr.length; st++)
								{
									splitTextArr[st].revert();
								}
							});
						}
				
						lineBuilds.addClass("show");
					}
				}
				else if (bounds.y > windowHeight)
				{
					
				}
			}
		
			let parrallaxTriggers = $(".scrollPara");
			
            /* this might also be dead code as "scale" isn't used in this block
             * -- however I'm less confident as it MAY be used in a closure and
             *  I just don't know what JS does with that */
			let scale = 1;
			
			if ($(window).width() >= 1920)
			{
				scale *= 1;
			}
			else if ($(window).width() > 750)
			{
				scale *= ($(window).width()/1920);
			}
			else
			{
				scale *= ($(window).width()/750);
			}
			
			
		
			for (let p=0; p<parrallaxTriggers.length; p++)
			{
				let item = parrallaxTriggers[p];
			
				if ($(item).attr("data-style"))
				{
					let bounds = parrallaxTriggers[p].getBoundingClientRect();
				
					let styleString =  $(item).attr("data-style");
			
					let params = [];
			
					for (let d=1; d<=20; d++)
					{
						if ($(item).attr("data-"+d+"-min") && $(item).attr("data-"+d+"-max"))
						{
							let type = "float";
					
							if ($(item).attr("data-"+d+"type"))
								type = $(item).attr("data-"+d+"type");
				
							params.push({
								count: d,
								min: $(item).attr("data-"+d+"-min"),
								max: $(item).attr("data-"+d+"-max"),
								type: type
							});
						}
					}
			
					for (let pa=0; pa<params.length; pa++)
					{
						let amount = 0;
						let param = params[pa];
				
			
						if (bounds.y > windowHeight)
						{
							// off screen bottom
							amount = 1;
					
						}
						else if ((bounds.y + bounds.height) < 0)
						{
							// off screen top
							amount = 0;
						}
						else
						{
							// on screen
							amount = bounds.y/windowHeight;
						}
				
						switch(param["type"])
						{
							case "float":
								amount = (parseFloat(param["max"])-parseFloat(param["min"]))*amount + parseFloat(param["min"]);
								break;
						}
				
						styleString = styleString.replaceAll("#"+param["count"]+"#", amount);
					}
				
					$(item).attr("style", styleString);
				}
			}
		}
		
	};
	
	$(window).on("scroll", (e)=>{ handleScroll(e);});
	
	window.ch7mode = "unknown";
	window.ch7legacycarousel = null;
	window.ch8presscarousel = null;
	
	
	let handleResize = ()=>{
		if ($("body").hasClass("chapter-8"))
		{
			if ($(window).width() > 750 && window.ch8mode != "desktop")
			{
				window.ch8mode = "desktop";
				
				if (ch8presscarousel != null)
					window.ch8presscarousel.destroy();
					
				window.ch8presscarousel = null;
			}
			else if ($(window).width() <= 750 && window.ch8mode != "mobile")
			{
				
				if ($("section.three-column-carousel").length)
				{
					window.ch8presscarousel = new Flickity( "section.three-column-carousel .inner", {
						cellAlign: 'left',
						prevNextButtons: false,
						wrapAround: true,
						percentPosition: false,
						pageDots: true,
						draggable: true,
						imagesLoaded: true,
						contain: true
					});
				}
				
				window.ch8mode = "mobile";
			}
		}
		
		if ($("body").hasClass("chapter-7"))
		{
			if ($(window).width() > 750 && window.ch7mode != "desktop")
			{
				window.ch7mode = "desktop";
				
				if (window.ch7legacycarousel != null)
				{
					window.ch7legacycarousel.destroy();
					window.ch7legacycarousel = null;
				}
			}
			else if ($(window).width() <= 750 && window.ch7mode != "mobile")
			{
				window.ch7legacycarousel = new Flickity( ".chapter-7 .legacy-making-tools .items-inner", {
					cellAlign: 'left',
					prevNextButtons: false,
					wrapAround: true,
					percentPosition: false,
					pageDots: true,
					draggable: true,
					imagesLoaded: true,
					contain: true
				});
				
				
				
				window.ch7mode = "mobile";
			}
		}
		
		let prHeight = $(".page-wrap").height();
		
		if ($(window).width() >= 1920)
		{
		
			$(window).attr("style", "");
			$(".page-wrap-outter").attr("style","");
			
			$("nav.chapter-nav").attr("style", "");
			
			$(".ch-8-presave-modal .inner").attr("style", "");
			
		}
		else if ($(window).width() > 750)
		{
			window.scale = $(window).width()/1920;
			
		
			$(".page-wrap").attr("style", "transform: scale("+scale+");");
			
			$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			
			//$("body").attr("style","height: "+((prHeight*scale)+ $("footer").height())+"px");
			
			$("nav.chapter-nav").attr("style", "transform: scale("+scale+");");
			
			$(".ch-8-presave-modal .inner").attr("style", "transform: scale("+scale+");");
			
		}
		
		if ($(window).width() > 750)
		{
			if (window.mode != "desktop")
			{
				window.mode = "desktop";
				
				if ($("#chapters-menu .chapters-outer").length)
				{
					if (window.navCarousel != null)
					{
						window.navCarousel.destroy();
					}
					
					window.navCarousel = new Flickity( "#chapters-menu .chapters-outer .chapters-inner", {
						cellAlign: 'right',
						prevNextButtons: false,
						wrapAround: false,
						freeScroll: true,
						contain: true,
						pageDots: false,
						draggable: true,
						imagesLoaded: false
					  });
	  
					//window.navCarousel.select($("#chapters-menu .chapters-outer .chapters-inner a").length-1, false, true );
	  
					setTimeout(()=>{
						window.navCarousel.resize();
					}, 500);
				}
				
				
				
				$(".stream-video-header").each((i, el)=>{
					let videoId = $(el).attr("data-videoDesktop");
					$(el).find(".videoHolder").remove();
					$(el).append($('<div class="videoHolder"><video></video></div>'));
		
					
					let player = videojs($(el).find(".videoHolder video")[0], {
						autoplay: true, muted: true, controls: false, loop: true, playsinline: true,
						sources: [,
						{
							src: videoId+'/playlist.mpd',
							type: 'application/dash+xml'
						},
						{
							src: videoId+'/full.mp4',
							type: 'video/mp4'
						}]
					});
					
					
				});
			}
		}
		else
		{
			
			window.scale = $(window).width()/750;
		
			$(".page-wrap").attr("style", "transform: scale("+scale+")");
			$(".page-wrap-outter").attr("style","height: "+(prHeight*scale)+"px");
			
			$("nav.chapter-nav").attr("style", "transform: scale("+scale+");");
			
			
			if ($(".ch-8-presave-modal").length)
			{
				$(".ch-8-presave-modal .inner").attr("style", "transform: scale("+scale+");");
			
			
				let modalHeight = (($(".ch-8-presave-modal .inner").height() + 300));
				console.log("height", modalHeight);
			
			
				if ($(window).height() / $(window).width() < $(".ch-8-presave-modal .inner").height() / $(".ch-8-presave-modal .inner").width())
				{
				
					let overlayScale = $(window).height() / modalHeight;
				
					if (overlayScale < window.scale)
					{
						$(".ch-8-presave-modal .inner").attr("style", "transform: scale("+overlayScale+");");
				
						console.log("scale for height", overlayScale);
					}
				}
			}			
			
			if (window.mode != "mobile")
			{
				window.mode = "mobile";
				
				closeChaptersNav();
				
				if ($("#chapters-menu .chapters-outer"))
				{
					if (window.navCarousel != null)
					{
						window.navCarousel.destroy();
						window.navCarousel = null;
					}
				}
				
				
				
				$(".stream-video-header").each((i, el)=>{
					//let videoId = $(el).attr("data-videoDesktop");
					let videoId = $(el).attr("data-videoMobile");
		
				    $(el).find(".videoHolder").remove();
					$(el).append($('<div class="videoHolder"><video></video></div>'));
		
					
					let player = videojs($(el).find(".videoHolder video")[0], {
						autoplay: true, muted: true, controls: false, loop: true, playsinline: true,
						sources: [
						{
							src: videoId+'/playlist.mpd',
							type: 'application/dash+xml'
						},
						{
							src: videoId+'/full.mp4',
							type: 'video/mp4'
						}]
					});
				});
			}
		}
		
		handleScroll();
	};
	
	
	
	
	window.allowPara = false;
	
	$(window).on("resize",()=>{ handleResize(); });
	handleResize();
	
	
	
	setTimeout(()=>{
		window.allowPara = true;
		handleScroll();
	}, 500);
	
	if ($(".more-chapters").length)
	{
		/*
		let startResize = ()=>{
			clearInterval(window.interval);
			window.interval = setInterval(()=>{
				handleResize();
			}, 100);
			
			setTimeout(()=>{
				clearInterval(window.interval);
			}, 1100);
		}
		*/
	
	
		$(".more-chapters a.btn.openner").on("click", (e)=>{
			$(".more-chapters .buttons-trigger").hide();
			$(".more-chapters .reveal").show();
			
			
			/*
			$(".more-chapters .reveal").addClass("active");
			
			
			startResize();
			
			
			$(".more-chapters .reveal .buttons").hide();
			setTimeout(()=>{
				$(".more-chapters .reveal .buttons").show();
				
				handleResize();
			}, 1000);
			*/
			
			
			
			handleResize();
			e.preventDefault();
		});
		
		$(".more-chapters .reveal a.btn.closer").on("click", (e)=>{
			
			$(".more-chapters .reveal").hide();
			
			$(".more-chapters .buttons-trigger").show();
			
			
			
			/*
			
			$(".more-chapters .reveal").removeClass("active");
			setTimeout(()=>{
				$(".more-chapters .buttons-trigger").show();
				
				handleResize();
			}, 1100);
			
			startResize();
			*/
			
			handleResize();
			e.preventDefault();
		});
	}
	
	if($("body").hasClass("chapter-5")) {
		window.carousel = new Flickity( ".ch5-carousel .carousel", {
			cellAlign: 'center',
			// prevNextButtons: false,
			wrapAround: false,
			percentPosition: false,
			pageDots: true,
			draggable: true,
			imagesLoaded: true
		  });
	}

	if($("body").hasClass("chapter-9")) {
	
		
		let count = $(".months-selector .items a").length;
		
		$( "<style>.chapter.chapter-9 .months-selector.open .items { max-height: "+(count * 130)+"px; }</style>" ).appendTo( "head") ;
		
		$(".months-selector").on("click", (e)=>{
			if (! $(".months-selector").hasClass("open") &&
				! $(".months-selector").hasClass("listing"))
			{
				$(".months-selector").addClass("open");
				
				e.preventDefault();
			}
		});
		
		$(".months-selector .past-months, .months-selector .close, .months-selector a.current").on("click", (e)=>{
		
			if ($(".months-selector").hasClass("open") &&
				! $(".months-selector").hasClass("listing"))
			{
				console.log("close");
			
				$(".months-selector").removeClass("open");
				e.preventDefault();
				e.stopPropagation();
			}
		});
		
		
	
        var listItems = document.querySelectorAll('.projector');
        window.carousels = [];

        listItems.forEach(function(item) {
		    let carousel = new Flickity( item, {
			    cellAlign: 'center',
			    prevNextButtons: true,
			    wrapAround: false,
			    percentPosition: false,
			    pageDots: true,
			    draggable: true,
			    imagesLoaded: true
		    });
            carousels.push( carousel);
        });

        console.log( "adding clickers");
        $(".tab-bar .tab").click(function(e){
            console.log( "clicked tab");
            var tabId = $(this).attr("href");

            $(".tab-bar .tab").removeClass("active");
            $(".tabitem").removeClass("active");

            $(this).addClass("active");
            $(tabId).addClass("active");

            carousels.forEach( function( flkty) { flkty.resize(); });
            handleResize();
            return false;
        });
	}
	
	$(window ).on("load", ()=>{
		handleResize();

		
		setTimeout(()=>{
			handleResize();
			
			
		}, 500);
	});
	
	setTimeout(()=>{
		handleResize();
	}, 500);
	
	
	setTimeout(()=>{

			
		if (window.location.hash == "#where-we-have-been" && $("section.chapters").length)
		{
			$("html, body").animate({ scrollTop: $("section.chapters").offset().top }, 500);
		}
	
	}, 1000);
	
	
	
	$("nav.top-nav .menu.m-only").on("click", (e)=>{
		openMobileMenu();
		
		e.preventDefault();
	});
	
	$("nav.top-nav .close.m-only").on("click", (e)=>{
		closeMobileMenu();
		
		e.preventDefault();
	});
	
	
	
	if ($("nav.top-nav a.overview-link").length)
	{
		$("nav.top-nav a.overview-link").on("click", (e)=>{
			if ($("a#past-chapters-toggle").hasClass("active"))
			{
				closeChaptersNav();
			}
			
			closeChaptersNavMobile();
			closeMobileMenu();
			
			
			$("html, body").animate({ scrollTop: $("section.overview").offset().top }, 500);
			
			e.preventDefault();
		});
	}
	
	$(".page-dimmer").on("click", (e)=>{
	
		if (! $("nav.top-nav .close.m-only").hasClass("show"))
		{
			closeChaptersNav();
		}
		
		e.preventDefault();
	});
	
	
	$("a#past-chapters-toggle").on("click", (e)=>{
		if (window.navCarousel != null)
		{
			if ($("a#past-chapters-toggle").hasClass("active"))
			{
				closeChaptersNav();
			}
			else
			{
				openChaptersNav();
			}
		}
		else
		{
			if ($("#chapters-menu").hasClass("active"))
			{
				closeChaptersNavMobile();
			}
			else
			{
				openChaptersNavMobile();
			}
		}
		
		e.preventDefault();
	});
	
	
	// chapter pages
	if ($("nav.chapter-nav a.chapter-nav").length)
	{
		$("nav.chapter-nav a.chapter-nav").on("click", (e)=>{
			
			if ($("nav.chapter-nav .menu-items").hasClass("active"))
			{
				$("nav.chapter-nav .menu-items").removeClass("active");
				
				$(window).off("click");
			}
			else
			{
				$("nav.chapter-nav .menu-items").addClass("active");
				
				$(window).on("click", (e)=>{
					if ($("nav.chapter-nav .menu-items").hasClass("active"))
					{
						$("nav.chapter-nav .menu-items").removeClass("active");
					}
				});
			}
			
			
			e.preventDefault();
			e.stopPropagation();
		});
		
		
	}
	
	$("a.scroll-prompt, .scroll-to-section").on("click", (e)=>{
		let reverseScale = 1;
		if ($(window).width() >= 1920)
		{
			reverseScale = 1;
		}
		else if ($(window).width() > 750)
		{
			reverseScale = ($(window).width()/1920);
		}
		else
		{
			reverseScale = ($(window).width()/750);
		}
		
		if ($("nav.chapter-nav").length)
			$("html, body").animate({ scrollTop: $("section.first-section").offset().top - ($("nav.chapter-nav").height()*reverseScale)}, 500);
		else
			$("html, body").animate({ scrollTop: $("section.first-section").offset().top }, 500);
		e.preventDefault();
	});

	window.youtubeplayer = null;
	
	$(".video-btn").on("click", (e)=>{
		
		let target = $(e.currentTarget);
		let videoid = target.attr("data-video");
		let type = target.attr("data-videotype");
		
		switch (type)
		{
			
			case "youtube":
				
				$(".video-dimmer").addClass("active");
				$(".video-modal").addClass("active");
				
				$(".video-modal").append($("<div id='video'></div>)"));
				
				window.youtubeplayer = new YT.Player('video', {
					height: '360',
					width: '640',
					videoId: videoid,
					playerVars: {
						modestbranding: 1,
						rel: 0,
						autoplay: 1
					}
				});
				
				//window.youtubeplayer.playVideo();
				
				
				break;
			case "stream":
				$(".video-dimmer").addClass("active");
				$(".video-modal").addClass("active");
				
				$(".video-modal").append($("<div id='video'><video class=\"video-js vjs-default-skin\"></video></div>)"));
				
				
				
				let player = videojs($(".video-modal #video video")[0], {
					autoplay: true, 
					auto: true,
					controls: true,
					playsinline: true,
					sources: [,
					{
						src: videoid+'/playlist.mpd',
						type: 'application/dash+xml'
					},
					{
						src: videoid+'/full.mp4',
						type: 'video/mp4'
					}]
				});
				
				//$(".video-modal #video > div").removeClass("vjs-controls-disabled");
				
				/*
				var inactivityTimeout = null;
				$(".video-modal").off("mousemove");
				$(".video-modal").on("mousemove", (event) => {
					
					if (inactivityTimeout != null) {
						clearTimeout(inactivityTimeout);
					} 
					inactivityTimeout = setTimeout(()=>{
						$(".video-modal #video > div").addClass("vjs-controls-disabled");
						controlBarVisible = false;
					}, 3000);
				});
				*/

				break;
			case "mp4":
				console.log("video type mp4");
				break;
			default:
				console.log("video type unknown", type);
				break;
		}
		
		e.preventDefault();
	});
	
	$(".video-dimmer, .video-modal .closer").on("click", (e)=>{
	
		
		
		if (window.youtubeplayer != null)
		{
			window.youtubeplayer.pauseVideo();
			window.youtubeplayer = null;
		}
		
		$(".video-modal #video").remove();
	
		// close video;
		$(".video-dimmer").removeClass("active");
		$(".video-modal").removeClass("active");
	});
	
	
	
	
	if ($(".ch-8-presave-modal").length)
	{
		$("body").addClass("noscroll");
		
		setTimeout(()=>{
			$(".ch-8-presave-modal .album").addClass("show");
			$("body").addClass("noscroll");
		}, 2000);
		
		let closePreSave = ()=>{
			$(".ch-8-overlay").removeClass("show");
			$(".ch-8-presave-modal").removeClass("show");
			
			$("body").removeClass("blur");
			$("body").removeClass("noscroll");
			
			setTimeout(()=>{
				$(".ch-8-overlay").removeClass("active");
				$(".ch-8-presave-modal").removeClass("active");
				$("body").removeClass("noscroll");
			}, 501);
		};
		
		
		
		$(".ch-8-presave-modal .close").on('click',(e)=>{
			closePreSave();
			e.preventDefault();
		});
		
		$(".ch-8-overlay").on('click',(e)=>{
			closePreSave();
			e.preventDefault();
		});
	}
	
	
	if ($(".video-inline").length)
	{
		for (let i=0; i< $(".video-inline").length; i++)
		{
			let el = $(".video-inline")[i];
			let videoId = $(el).attr("data-videoId");
			
			$(el).find(".videoHolder").remove();
			$(el).append($('<div class="videoHolder"><video></video></div>'));

			
			let player = videojs($(el).find(".videoHolder video")[0], {
				autoplay: true, muted: true, controls: false, loop: true, playsinline: true,
				sources: [
				{
					src: videoId+'/playlist.mpd',
					type: 'application/dash+xml'
				},
				{
					src: videoId+'/full.mp4',
					type: 'video/mp4'
				}]
			});
		}
	}
	
});
